import {
	adCopyStrategyTargetGroupLabels,
	adCopyStrategyGroupCategoryLabels,
} from "../../../../../label/ad-copy/activity";
import {
	AdCopyStrategyGroup,
	AdCopySkinAttribute,
	AdCopySensitiveSkin,
	AdCopyChannel,
	AdCopyFoundationType,
	AdCopyFacewashFunction,
	AdCopyBeautyType,
	AdCopyChildren,
	AdCopyOccupation,
	AdCopyMarried,
	AdCopyPrice,
	AdCopySensitiveSkinFrequencyRank,
	AdCopyLipstickType,
	AdCopyEyeshaowType,
	AdCopySkinProblemFrequency,
	AdCopyAestheticMedicineExperience,
	AdCopyActivityWithBrand,
	adCopySensitiveSkinFrequencyOptionsWithRank,
	AdCopyGypsy,
} from "../../../../models/ad-copy/activity";
import { AdCopyJob } from "../../../../models/ad-copy/job";
import { GeneralGroupQuota, adCopyPriceChoices } from "../../../../models/category";
import {
	genderAdCopyQuename,
	ageAdCopyQuename,
	categoryAdCopyQuename,
	usedBrandAdCopyQuePrefix,
	marriedAdCopyQuename,
	beautyTypeAdCopyQuename,
	channelAdCopyQuename,
	facewashFunctionAdCopyQuename,
	familyAdCopyQuename,
	familyChoices,
	foundationTypeAdCopyQuename,
	occupationAdCopyQuename,
	priceAdCopyQuename,
	sensitiveSkinAdCopyQuename,
	skinAttributeAdCopyQuename,
	sensitiveSkinFrequencyAdCopyQuename,
	aestheticMedicineExperienceAdCopyQuename,
	skinProblemFrequencyAdCopyQuename,
	eyeshadowTypeAdCopyQuename,
	lipstickTypeAdCopyQuename,
	gypsyAdCopyQuename,
} from "../config";
import {
	getCategoryGeneralGroupUserQuotas,
	getConceptNumbersForQuota,
	hasEyeshadowType,
	hasFacewashOrCleansing,
	hasFoundationType,
	hasLipstickType,
	makeCategoryUserQuotaCode,
} from "../helpers";
import { isNotEmptyArray } from "../../../common";
import { isDefined } from "../../common";
import { QuotaWithTranslateCondition } from "@muscat/types";
import { Age, Gender } from "../../../../models/shared";
import { Category } from "../../../../models/category";

export const makePriceChoice = (price: AdCopyPrice) => {
	const { to, from } = price;
	return adCopyPriceChoices.filter((choice) => {
		// toがundefinedは以上の判定。選択肢のfromよりもターゲットのfromが大きければ有効。
		if (to === undefined) {
			return from <= choice.from;
		}
		// fromがundefinedは以下の判定toよりも大きければ有効。
		if (from === undefined) {
			return to >= choice.to;
		}
		return choice.from <= to && from <= choice.to;
	}, []);
};

export const makeGenderCondition = (category: Category) => {
	return `${genderAdCopyQuename}=${category.gender === "male" ? 1 : 2}`;
};

export const getGenderLabel = (category: Category) => {
	return Gender[category.gender];
};

export const makeAgeCondition = (age: Age) => {
	return `${ageAdCopyQuename}>=${age.from} and ${ageAdCopyQuename}<=${age.to}`;
};

export const makeAdCopyAgeLabel = (age: Age) => {
	return `${age.from}〜${age.to}歳`;
};

export const make3DigitCode = (code: number) => {
	return code.toString().padStart(3, "0");
};

export const makeAdCopyPriceLabel = (price?: AdCopyPrice): string => {
	if (!price) return "設定なし";
	// if (price.label) return price.label;
	if (price.from !== undefined && price.to === undefined) {
		return price.from.toLocaleString() + "円以上";
	}
	if (price.from === undefined && price.to !== undefined) {
		return price.to.toLocaleString() + "円以下";
	}
	if (price.from !== undefined && price.to !== undefined) {
		return `${price.from.toLocaleString()}〜${price.to.toLocaleString()}円`;
	}
	return "設定なし";
};

export const makeAdCopyCategoryGroupText = (gender: keyof typeof Gender): string => {
	return [`性別：${Gender[gender]}`].join("、");
};

export const makeCategoryBrandUserCondition = async (
	activity: AdCopyActivityWithBrand,
	job: AdCopyJob,
): Promise<string> => {
	// 需要予測が「無し」で呼ばれていた場合、ブランドユーザ設問は不要なので落とす
	if (job.isDemandForecastNeeded === false) throw new Error("ブランドユーザ設問の設定は出来ません");
	const { category, targetCategoryBrandValues } = activity;
	const categorySurveyChoiceValues = category.surveyChoice.map((sc) => sc.value);
	const categoryUserCondition = `${categoryAdCopyQuename}=${categorySurveyChoiceValues.join(",")}`;

	const brandUserConditions = categorySurveyChoiceValues.map((categorySurveyChoiceValue) => {
		const brandAdCopyQuename = `${usedBrandAdCopyQuePrefix}-${categorySurveyChoiceValue}`;
		return `${brandAdCopyQuename}=${targetCategoryBrandValues.join(",")}`;
	});
	const brandUserCondition = brandUserConditions.join(" or ");

	return `${genderAdCopyQuename}=${
		category.gender === "male" ? 1 : 2
	} and ${categoryUserCondition} and (${brandUserCondition})`;
};

export const makeAllCategoryUserQuotaCodes = (job: AdCopyJob, generalGroupQuota: GeneralGroupQuota[]): string[] => {
	const conceptNumbers = getConceptNumbersForQuota(job);

	return conceptNumbers.flatMap((conceptNumber) => {
		return getCategoryGeneralGroupUserQuotas(generalGroupQuota).flatMap((_quota, index) => {
			return makeCategoryUserQuotaCode(conceptNumber, index);
		});
	});
};

export function composeGypsySubLabel(gypsy: AdCopyGypsy) {
	const subLabels = [
		...(gypsy.beautyType ? [AdCopyBeautyType[gypsy.beautyType]] : []),
		...(gypsy.facewashFunction ? [AdCopyFacewashFunction[gypsy.facewashFunction]] : []),
		...(gypsy.foundationType ? [AdCopyFoundationType[gypsy.foundationType]] : []),
		...(gypsy.lipstickType ? [AdCopyLipstickType[gypsy.lipstickType]] : []),
		...(gypsy.eyeshadowType ? [AdCopyEyeshaowType[gypsy.eyeshadowType]] : []),
	];

	return subLabels.length <= 0 ? "" : `（${subLabels.join("/")}）`;
}

export const makeAdCopyStrategyGroupText = (
	strategyTargetGroup: AdCopyStrategyGroup,
	gender: keyof typeof Gender,
): string => {
	const {
		age,
		married,
		children,
		occupation,
		skinAttribute,
		sensitiveSkin,
		sensitiveSkinFrequency,
		categoryOrAnd,
		channel,
		foundationType,
		facewashFunction,
		skinProblemFrequency,
		lipstickType,
		eyeshadowType,
		gypsy,
		aestheticMedicineExperience,
		category: categoryConfig,
		nonPurchaseCategoryConfig,
	} = strategyTargetGroup;
	const tmp = [`性別：${Gender[gender]}、${adCopyStrategyTargetGroupLabels.age}：${makeAdCopyAgeLabel(age)}`];
	if (isNotEmptyArray(married)) {
		tmp.push(`${adCopyStrategyTargetGroupLabels.married}：${married.map((target) => AdCopyMarried[target]).join()}`);
	}
	if (isNotEmptyArray(children)) {
		tmp.push(`${adCopyStrategyTargetGroupLabels.children}：${children.map((target) => AdCopyChildren[target]).join()}`);
	}
	if (isNotEmptyArray(occupation)) {
		tmp.push(
			`${adCopyStrategyTargetGroupLabels.occupation}：${occupation.map((target) => AdCopyOccupation[target]).join()}`,
		);
	}
	if (isNotEmptyArray(skinAttribute)) {
		tmp.push(
			`${adCopyStrategyTargetGroupLabels.skinAttribute}：${skinAttribute
				.map((target) => AdCopySkinAttribute[target])
				.join()}`,
		);
	}
	if (isNotEmptyArray(sensitiveSkin)) {
		tmp.push(
			`${adCopyStrategyTargetGroupLabels.sensitiveSkin}：${sensitiveSkin
				.map((target) => AdCopySensitiveSkin[target])
				.join()}`,
		);
	}
	if (isNotEmptyArray(sensitiveSkinFrequency)) {
		tmp.push(
			`${adCopyStrategyTargetGroupLabels.sensitiveSkinFrequency}：${sensitiveSkinFrequency
				.map((target) => AdCopySensitiveSkinFrequencyRank[target])
				.join()}`,
		);
	}
	if (isNotEmptyArray(channel)) {
		tmp.push(`${adCopyStrategyTargetGroupLabels.channel}：${channel.map((target) => AdCopyChannel[target])}`);
	}
	if (hasFoundationType(strategyTargetGroup)) {
		tmp.push(
			`${adCopyStrategyTargetGroupLabels.foundationType}：${foundationType.map(
				(target) => AdCopyFoundationType[target],
			)}`,
		);
	}
	if (hasFacewashOrCleansing(strategyTargetGroup)) {
		tmp.push(`${adCopyStrategyTargetGroupLabels.facewashFunction}：${AdCopyFacewashFunction[facewashFunction]}`);
	}
	if (hasLipstickType(strategyTargetGroup)) {
		tmp.push(
			`${adCopyStrategyTargetGroupLabels.lipstickType}：${lipstickType
				.map((target) => AdCopyLipstickType[target])
				.join()}`,
		);
	}
	if (hasEyeshadowType(strategyTargetGroup)) {
		tmp.push(
			`${adCopyStrategyTargetGroupLabels.eyeshadowType}：${eyeshadowType
				.map((target) => AdCopyEyeshaowType[target])
				.join()}`,
		);
	}
	if (isNotEmptyArray(skinProblemFrequency)) {
		tmp.push(
			`${adCopyStrategyTargetGroupLabels.skinProblemFrequency}：${skinProblemFrequency
				.map((target) => AdCopySkinProblemFrequency[target])
				.join()}`,
		);
	}
	if (gypsy) {
		tmp.push(`${adCopyStrategyTargetGroupLabels.gypsy}：${gypsy.surveyChoice.label}${composeGypsySubLabel(gypsy)}`);
	}
	if (isNotEmptyArray(aestheticMedicineExperience)) {
		tmp.push(
			`${adCopyStrategyTargetGroupLabels.aestheticMedicineExperience}：${aestheticMedicineExperience
				.map((target) => AdCopyAestheticMedicineExperience[target])
				.join()}`,
		);
	}

	const categoryTexts: string[] = [];
	for (const c of categoryConfig) {
		const { price, beautyType, usedBrand, unusedBrand, surveyChoice: surveyChoiceNumber } = c;
		const targetCategory = c.category;
		const surveyChoice = targetCategory.adCopySurveyChoice.find((sc) => sc.value === surveyChoiceNumber);
		const categoryTmp = [`${adCopyStrategyGroupCategoryLabels.price}：${makeAdCopyPriceLabel(price)}`];
		if (isNotEmptyArray(beautyType)) {
			categoryTmp.push(
				`${adCopyStrategyGroupCategoryLabels.beautyType}：${beautyType
					.map((target) => AdCopyBeautyType[target])
					.join("、")}`,
			);
		}
		// ブランド名のリストになる。
		if (isNotEmptyArray(usedBrand)) {
			categoryTmp.push(`${adCopyStrategyGroupCategoryLabels.usedBrand}：${usedBrand.join("、")}`);
		}
		if (isNotEmptyArray(unusedBrand)) {
			categoryTmp.push(`${adCopyStrategyGroupCategoryLabels.unusedBrand}：${unusedBrand.join("、")}`);
		}
		categoryTexts.push(`＜${surveyChoice.label}＞\n${categoryTmp.join("\n")}`);
	}
	if (categoryTexts.length) {
		tmp.push(`\n■ 以下カテゴリ間の条件は${categoryOrAnd}で設定する\n${categoryTexts.join(`\n`)}`);
	}

	// 過去1年非購入カテゴリ
	if (isDefined(nonPurchaseCategoryConfig) && isNotEmptyArray(nonPurchaseCategoryConfig.surveyChoices)) {
		const { categoryOrAnd, surveyChoices } = nonPurchaseCategoryConfig;
		const nonPurchaseCategoryEnqueteChoicesLabel = `\n■ 以下${
			adCopyStrategyTargetGroupLabels.nonPurchaseCategoryConfig
		}は${categoryOrAnd}で設定する: \n${surveyChoices.map((choice) => choice.label).join(", ")}\n`;
		tmp.push(nonPurchaseCategoryEnqueteChoicesLabel);
	}

	return tmp.join("\n");
};

export const makeAdCopyStrategyCondition = (strategyTargetGroup: AdCopyStrategyGroup, category: Category): string => {
	const {
		age,
		married,
		children,
		occupation,
		skinAttribute,
		sensitiveSkin,
		sensitiveSkinFrequency,
		categoryOrAnd,
		channel,
		foundationType,
		facewashFunction,
		skinProblemFrequency,
		lipstickType,
		eyeshadowType,
		gypsy,
		aestheticMedicineExperience,
		category: categoryConfig,
		nonPurchaseCategoryConfig,
	} = strategyTargetGroup;

	const conditions: string[] = [makeGenderCondition(category)];
	const ageCondition = `${ageAdCopyQuename}>=${age.from} and ${ageAdCopyQuename}<=${age.to}`;
	conditions.push(ageCondition);

	if (isNotEmptyArray(married)) {
		conditions.push(
			`${marriedAdCopyQuename}=${married
				.map((m) => {
					return m === "married" ? "2" : "1,3";
				})
				.join(",")}`,
		);
	}
	if (isNotEmptyArray(children)) {
		conditions.push(
			`${familyAdCopyQuename}=${children
				.map((c) => {
					const choice = familyChoices.find((choice) => choice.text === AdCopyChildren[c]);
					return choice.value;
				})
				.join(",")}`,
		);
	}
	if (isNotEmptyArray(occupation)) {
		conditions.push(
			`${occupationAdCopyQuename}=${occupation
				.map((o) => {
					if (o === "employed") {
						return "1~5";
					}
					if (o === "student") {
						return "6,7";
					}
					if (o === "notEmployed") {
						return "8,9";
					}
					throw new Error("設定できない項目がある（職業）。");
				})
				.join(",")}`,
		);
	}
	if (isNotEmptyArray(skinAttribute)) {
		// enumは0始まりなので1ずらす。
		conditions.push(
			`${skinAttributeAdCopyQuename}=${skinAttribute
				.map((s) => {
					return Number(s) + 1;
				})
				.join(",")}`,
		);
	}
	if (isNotEmptyArray(sensitiveSkin)) {
		// enumは0始まりなので1ずらす。
		conditions.push(
			`${sensitiveSkinAdCopyQuename}=${sensitiveSkin
				.map((s) => {
					return Number(s) + 1;
				})
				.join(",")}`,
		);
	}
	if (isNotEmptyArray(sensitiveSkinFrequency)) {
		// rank は 0始まり
		const targetValues = adCopySensitiveSkinFrequencyOptionsWithRank
			.filter((s) =>
				sensitiveSkinFrequency
					.map((s) => {
						return Number(s);
					})
					.includes(s.rank),
			)
			.map((s) => s.value);

		conditions.push(`${sensitiveSkinFrequencyAdCopyQuename}=${targetValues.join(",")}`);
	}
	if (isNotEmptyArray(channel)) {
		// enumは0始まりなので1ずらす。
		const tmp: string[] = [];
		for (const { surveyChoice } of categoryConfig) {
			tmp.push(
				`${channelAdCopyQuename}s${surveyChoice}=${channel
					.map((s) => {
						return Number(s) + 1;
					})
					.join(",")}`,
			);
		}
		conditions.push(`(${tmp.join(" or ")})`);
	}

	if (hasFoundationType(strategyTargetGroup)) {
		// enumは0始まりなので1ずらす。
		conditions.push(
			`${foundationTypeAdCopyQuename}=${foundationType
				.map((s) => {
					return Number(s) + 1;
				})
				.join(",")}`,
		);
	}
	if (hasFacewashOrCleansing(strategyTargetGroup)) {
		// enumは0始まりなので1ずらす。
		conditions.push(`${facewashFunctionAdCopyQuename}=${Number(facewashFunction) + 1}`);
	}
	if (hasLipstickType(strategyTargetGroup)) {
		// enumは0始まりなので1ずらす。
		conditions.push(
			`${lipstickTypeAdCopyQuename}=${lipstickType
				.map((s) => {
					return Number(s) + 1;
				})
				.join(",")}`,
		);
	}
	if (hasEyeshadowType(strategyTargetGroup)) {
		// enumは0始まりなので1ずらす。
		conditions.push(
			`${eyeshadowTypeAdCopyQuename}=${eyeshadowType
				.map((s) => {
					return Number(s) + 1;
				})
				.join(",")}`,
		);
	}
	if (isNotEmptyArray(skinProblemFrequency)) {
		// enumは0始まりなので1ずらす。
		conditions.push(
			`${skinProblemFrequencyAdCopyQuename}=${skinProblemFrequency
				.map((s) => {
					return Number(s) + 1;
				})
				.join(",")}`,
		);
	}
	if (gypsy != null) {
		// enumは0始まりなので1ずらす。
		conditions.push(`${gypsyAdCopyQuename}=${gypsy.gypsyChoices.map((s) => Number(s) + 1).join(",")}`);
	}

	if (isNotEmptyArray(aestheticMedicineExperience)) {
		// enumは0始まりなので1ずらす。
		conditions.push(
			`${aestheticMedicineExperienceAdCopyQuename}=${aestheticMedicineExperience
				.map((s) => {
					return Number(s) + 1;
				})
				.join(",")}`,
		);
	}
	if (isDefined(nonPurchaseCategoryConfig) && isNotEmptyArray(nonPurchaseCategoryConfig.surveyChoices)) {
		const targetValues = nonPurchaseCategoryConfig.surveyChoices.map((choice) => choice.value);
		const nonPurchaseCategoryCondition = targetValues
			.map((value) => `${categoryAdCopyQuename}!=${value}`)
			.join(` ${nonPurchaseCategoryConfig.categoryOrAnd} `);
		conditions.push(`(${nonPurchaseCategoryCondition})`);
	}
	/**
			usedBrand?: (ObjectId | string)[];
			unusedBrand?: (ObjectId | string)[];
		 */
	const categoryConditions: string[] = [];
	for (const target of categoryConfig) {
		const targetCategory = target.category;
		const surveyChoice = targetCategory.adCopySurveyChoice.find((sc) => sc.value === target.surveyChoice);
		const { adCopyBrandlist } = targetCategory;
		const tmpConditions: string[] = [`${categoryAdCopyQuename}=${surveyChoice.value}`];
		if (target.price && (target.price.from || target.price.to)) {
			const choices = makePriceChoice(target.price);
			tmpConditions.push(
				`${priceAdCopyQuename}s${surveyChoice.value}=${choices.map((choice) => choice.value).join(",")}`,
			);
		}
		if (isNotEmptyArray(target.beautyType)) {
			tmpConditions.push(
				`${beautyTypeAdCopyQuename}s${surveyChoice.value}=${target.beautyType
					.map((s) => {
						return Number(s) + 1;
					})
					.join(",")}`,
			);
		}
		if (isNotEmptyArray(target.usedBrand)) {
			const targetBrand = adCopyBrandlist.filter((b) => target.usedBrand.includes(b.brandName));
			tmpConditions.push(
				`${usedBrandAdCopyQuePrefix}-${surveyChoice.value}=${targetBrand
					.map((b) => b.surveyChoice.map((choice) => choice.value).join(","))
					.join(",")}`,
			);
		}
		if (isNotEmptyArray(target.unusedBrand)) {
			const targetBrand = adCopyBrandlist.filter((b) => target.unusedBrand.includes(b.brandName));
			tmpConditions.push(
				`${usedBrandAdCopyQuePrefix}-${surveyChoice.value}!=${targetBrand
					.map((b) => b.surveyChoice.map((choice) => choice.value).join(","))
					.join(",")}`,
			);
		}
		categoryConditions.push(`(${tmpConditions.join(" and ")})`);
	}
	if (categoryConditions.length) {
		conditions.push(`(${categoryConditions.join(` ${categoryOrAnd} `)})`);
	}
	return conditions.join(" and ");
};

export const makeAdCopyCategoryUserCondition = (category: Category) => {
	const categorySurveyChoiceValues = category.adCopySurveyChoice.map((sc) => sc.value);
	return `${categoryAdCopyQuename}=${categorySurveyChoiceValues.join(",")}`;
};

/**
 * 全体回収数が0かどうか
 */
export const isTotalQuotaCountZero = (quotas: QuotaWithTranslateCondition[]) => {
	const total = quotas.reduce((acc, quota) => {
		return acc + quota.count;
	}, 0);
	return total === 0;
};
