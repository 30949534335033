import { BrandUserSizeData } from "../../../ad-copy-analytics/reference-source-data";
import { CategoryUserSizeData } from "../../../ad-copy-analytics/reference-source-data";
import { RecognitionRateBrandData } from "../../../ad-copy-analytics/reference-source-data";
import { RecognitionRateCategoryData } from "../../../ad-copy-analytics/reference-source-data";
import { AdCopyConcept, AdCopyConceptKey } from "../concept";
import { DemandSegmentWithoutStrategyTarget, DemandSegmentWithStrategyTarget } from "./aggregateAxisNameMap";

/** 需要予測用自社テストコンセプト */
export type AdCopyConceptTypeForDemand = Exclude<AdCopyConceptKey, "benchmark">;
export type AdCopyConceptForDemand = Omit<AdCopyConcept, "type"> & { type: AdCopyConceptTypeForDemand };

/**
 * @property {number} gRepeat 購入者購入数量(ブランド) or 購入者購入数量(カテゴリ)
 * @property {number} gRatio_IBBY UMIベース比率(ブランド)
 * @property {number} gRatio_LocalOther UMIベース比率(ブランド)
 * @property {number} gUnreport UMIベース比率(カテゴリ)
 * @property {BrandUserSizeData | undefined} brandUserSizeData Copy_ユーザ数(ブランド)
 * @property {CategoryUserSizeData} categoryUserSizeData Copy_ユーザ数(カテゴリ)
 * @property {RecognitionRateBrandData | undefined} recognitionRateBrandData Copy_セグメント別認知率(ブランド)
 * @property {RecognitionRateCategoryData} recognitionRateCategoryData Copy_セグメント別認知率(カテゴリ)
 */
export type SimulationReferenceData = {
	gRepeat: number;
	gRatio_IBBY: number;
	gRatio_LocalOther: number;
	gUnreport: number;
	brandUserSizeData: BrandUserSizeData | undefined;
	categoryUserSizeData: CategoryUserSizeData;
	recognitionRateBrandData: RecognitionRateBrandData | undefined;
	recognitionRateCategoryData: RecognitionRateCategoryData;
};

export type SimulationData = {
	concept: AdCopyConceptForDemand;

	/** アクティビテ想定商品認知率 */
	goalRecognitionRate: number;

	/** アクティビテ登録時 目標値 年間購買個数（万個） */
	goalSalesVolume: number;

	/** ブランドユーザー 計算用定数 C3 */
	brandUserConstant: number;

	/** ブランドノンユーザー 計算用定数 C4 */
	brandNonUserConstant: number;

	/** カテゴリノンユーザー 計算用定数 C5 */
	categoryUserConstant: number;

	/** ポテンシャル需要合計: 推定年間購買個数（万個）	 */
	potentialSalesVolume: number;

	/** 対目標比 */
	ratioToTargetSalesVolume: number;

	/** ポテンシャル需要 うちトライアル購買個数（万個） 	 */
	trialSalesVolume: number;

	/** ポテンシャル需要 うちトライアル購買個数(補正前)（万個） 	 */
	trialSalesVolumeBeforeCorrection: number;

	/** トライアル購入数量計算時に使ったセグメント別の変数 */
	trialSalesVolumeSegmentVariables: TrialSalesVolumeSegmentVariable[];

	/** トライアル購買個数 + トライアル申告漏れ */
	trialSalesVolumeAddUnreported: number;

	/** ポテンシャル需要 うちトライアル申告漏れ 購買個数（万個） 	 */
	trialUnreportedSalesVolume: number;

	/** ポテンシャル需要 うちリピート購買個数（万個）*/
	repeatSalesVolume: number;

	/** ポテンシャル需要 うちリピート申告漏れ 購買個数（万個） 	 */
	repeatUnreportedSalesVolume: number;

	/** リピート購買個数 + リピート申告漏れ */
	repeatSalesVolumeAddUnreported: number;

	/** ポテンシャル需要 うちローカルその他購買個数（万個）*/
	localOtherSalesVolume: number;

	/** ポテンシャル需要 うちインバウンド・バイヤー購買個数（万個）*/
	inboundBuyerSalesVolume: number;

	/** 現行品上市後1年間トライアル売上数量(SLI,SCI)) (万個) */
	currentProductTrialSalesVolume?: number;

	/** "現行刺激のトライアル売上数量(補正前) (万個)  */
	currentProductTrialSalesVolumeBeforeCorrection?: number;

	/** 参照データ */
	referenceData: SimulationReferenceData;
};

export type TrialSalesVolumeSegmentVariable = {
	/** 購入意向one number score */
	oneNumberScore: number;
} & (
	| {
			/** 戦略ターゲット対象者の該当率 */
			strategyTargetRatio: number;
			/** セグメント種類 */
			demandSegment: DemandSegmentWithStrategyTarget;
	  }
	| {
			/** 非戦略ターゲット対象者の該当率 */
			nonStrategyTargetRatio: number;
			/** セグメント種類 */
			demandSegment: DemandSegmentWithoutStrategyTarget;
	  }
);
